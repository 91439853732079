import './CaseStudyOurOffering.css';

const CaseStudyOurOffering = () => {
    return (
        <>
            <section className="caseStudyOurOffering space">
                <div className='container-type-two'>
                    <p className='left-text'>Our<br />offering</p>
                </div>
                <div className="container">
                    <h2 data-aos="fade-up" data-aos-easing="linear"
                        data-aos-duration="500" >Enabling quality improvement and delivery</h2>
                    <p data-aos="fade-up" data-aos-easing="linear"
                        data-aos-duration="500">The use of technology has become increasingly prevalent in healthcare, and having a solid digital presence is crucial for today’s healthcare facilities. The client wanted to transform its legacy operations and healthcare services to advance digital solutions, extending convenience and quality to its users. Struggling with complex offline medical records, inefficient workflow management, and suboptimal inventory, we helped the client design a comprehensive and scalable digital strategy aligning with the company’s overall business goals. </p>
                    <p data-aos="fade-up" data-aos-easing="linear"
                        data-aos-duration="500">Our technology experts streamlined immediate improvement corners through our HMS (hospital management system) modules, including lab management, patient onboarding, OPD services, and patient response system. To enhance the monitoring of patient-in and patient-out, we integrated intelligent health monitoring and management tools and enabled automated performance management of service quality and delivery.</p>
                    <img src="https://assets-global.website-files.com/641da72be745998690494cd6/64c77c51032bc429d1a2537a_CuraPatient%20dash%20dashboard.png" alt="alt" />
                    <h2 data-aos="fade-up" data-aos-easing="linear"
                        data-aos-duration="500" >Enabling quality improvement and delivery</h2>
                    <p data-aos="fade-up" data-aos-easing="linear"
                        data-aos-duration="500">By leveraging custom-built mobile health solutions, our team focused on connecting communities and users with easy access to health facilities by anyone, anywhere. This enabled incredible speed and efficiency in delivering crucial care on time. The solutions also helped generate a better patient care experience and refined the company’s financial operations. To elevate the approval flow and reduce time to appointments and scheduling, we also introduced a centralized portal with digital forms, emails, alerts, and feedback, replacing legacy software and manual records.</p>
                    <p data-aos="fade-up" data-aos-easing="linear"
                        data-aos-duration="500">Implementing smart reporting and analytics, the client was able to improve clinical processes by over 60%. The centralized portal also helped reduce the wait time from 88% to about 20% within few weeks of adopting frictionless engagement tools. Our team worked on post-care service transformation to address re-appointment scheduling and pharmacy subscription platform, reducing multiple patient’s visits to healthcare facilities significantly. The overall transformation exercise helped client generate greater brand trust, and accelerated the client’s competitiveness in the digital health landscape. </p>
                </div>
                <div className='container-type-two'>
                    <p className='left-text'>Result</p>
                </div>
                <div className="container">
                    <h2>Providing results that exceeded the client’s expectations</h2>
                    <ul>
                        <li>Launched a successful final app using a UI/UX prototype model to help client achieve reduced development time and testing time</li>
                        <li>Provided a 100% seamless platform connecting public programs, providers, and residents. </li>
                        <li>Enhanced customer experience by cutting vaccination encounters from 30 minutes to 3 minutes. </li>
                        <li>Improved productivity for the health system as an extension of EHR.</li>
                    </ul>
                </div>
                
            </section>
        </>
    )
}
export default CaseStudyOurOffering;