import './CommanComponent.css'

const Banner = ({ image, heading, text }) => {
    

    return (
        <div className="banner" style={{ backgroundImage:`url(${image})`}}>
            <div className="container">
                <div className='banner-inner'>
                    <div className="banner-heading">
                        <h1>{heading}</h1>
                        <p>{text}</p>
                    </div>
                </div>

            </div>
        </div>
    );
}
export default Banner