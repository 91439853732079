import sol1 from './Image/solutionImage/Artboard 1 copy 19.png'
import sol2 from './Image/solutionImage/Artboard 1 copy 3.png'
import sol3 from './Image/solutionImage/Artboard 1 copy 5.png'
import sol4 from './Image/solutionImage/Artboard 1 copy 7.png'

export const solution = [
    { number:'01', image:sol1,  heading: "UX Design: Crafting Engaging Digital Experiences", text:"Generating interactive digital experiences through a user-centric design strategy is necessary for producing items and services that hook customers, provide value, and stand out in today's digital world. It is a philosophy that acknowledges that the prosperity of a digital outcome relies heavily on how perfectly it serves the recipients it is tailored for." },
    { number:'02', image:sol2,   heading: "The Significance of Advanced Data Analytics", text:"Advanced information analytics is at the frontline of a healthcare revolution. It can transform patient care, streamline healthcare operations, and force clinical discoveries. However, it also faces challenges related to data privacy, integration, and regulatory compliance. As technology advances and the healthcare enterprise embraces records-driven processes, the destiny of healthcare looks brighter than ever, with analytics playing a pivotal position in improving results for sufferers and providers alike. With ongoing tendencies and improvements in this field, we can count on healthcare information analytics to hold its journey toward shaping a healthier world." },
    { number:'03', image:sol3,   heading: "The Science Behind Improving Customer Experience Through Data ", text:"In today's digital and hyperconnected environment, data analytics offers companies a comprehensive view into the inner functions of their business, customers, and requirements. It facilitates a thorough examination of everyday business operations, how goods and services are processed, and what employees and customers look for." },
    { number:'04', image:sol4,   heading: "Telemedicine: A Viable Healthcare Tech Solution", text:"Digital transformation has ushered in a new era of healthcare delivery, and telemedicine is one of its most significant advancements. As a result of the COVID-19 pandemic, virtual care methods have become increasingly vital to the health technology world and a key enabler to the global healthcare transformation landscape." },

  ];