import Banner from "../../Component/CommanComponent/Banner";
import ServiceCard from "../../Component/CommanComponent/ServiceCard";
import creative from './Image/creative/creativebanner.png';
import midImage from './Image/creative/mid image.webp';
import './Servicepage.css';
import Strategy from './Image/creative/Strategy Development.webp';
import CrossPlatform from './Image/creative/Cross-Platform Experience_.webp';
import Custom from './Image/creative/Custom Design Services_.webp';
import ux from './Image/creative/UX & Accessibility Audit_.webp';
import data from './Image/creative/Data Visualization_.webp';
import Wireframes from './Image/creative/Wireframes & Prototypes_.webp';
import CustomerStory from "../../Component/CommanComponent/CustomerStory";
import RelatedInsights from '../../Component/CommanComponent/RelatedInsights';
import Cta from "../../Component/CommanComponent/Cta";




const ServicePage = () => {
    const content = {
        heading: 'Creative',
        text: 'Building a unique digital experience and empowering brands'
    }

    const cardcontent = [
        { id: 1, image: `${Strategy}`, heading: "Strategy and Consulting", text: "We leverage our infinity learning module for understanding customer personas, dynamic mindsets, and business needs to consult and formulate strategies paired with innovative UX ideas and a global design-thinking approach.", colors: "#f6f6f6" },
        { id: 2, image: `${CrossPlatform}`, heading: "Cross-Platform Experience", text: "Our design and usability practices support cross-platform adaptation and ensure a cohesive and engaging user journey on any device. Successive creative team creates seamless customer experience by leveraging some of the world’s most advanced UX design tools", colors: "#e6edfa" },
        { id: 3, image: `${Custom}`, heading: 'Custom Design Services', text: 'We create outstanding UI/ UX designs for digital products that are memorable and unique. Our web and mobile design team specializes in designing disruptive user experiences that are hyper-customized, user-friendly, convenient, compatible, and accessible.', colors: '#f6f6f6' },
        { id: 4, image: `${ux}`, heading: 'UX & Accessibility Audit', text: 'Our audit team deep dive into understanding existing user-facing issues and potential challenges to product design. We use a proven usability matrix to evaluate and redefine product design strategy that serves the best for business and users.', colors: '#f6f6f6' },
        { id: 5, image: `${data}`, heading: 'Data Visualization', text: 'Cutting beyond bar charts, we design insightful visualizations to simplify complex data and give a simple view for better decision-making. Our designers help you transform complicated data collection into a visually appealing, sophisticated dashboard.', colors: "#e6edfa" },
        { id: 6, image: `${Wireframes}`, heading: 'Wireframes & Prototypes', text: 'We help businesses of all sizes to align stakeholders’ ideas and create a shared vision. Our design experts build MVPs, mock-ups, wireframes, and working prototypes to test with initial users and iterate based on review analytics and feedback sessions to drive future success.', colors: '#f6f6f6' }

    ]
    return (
        <>
            <Banner image={creative} {...content} />
            <section className="about-service space">
                <div className='container-type-two'>
                    <p className='left-text'>UI/ UX Design & <br />Creative Consulting</p>
                </div>
                <div className="container">
                    <div className="row">
                        <img src={midImage} alt={content.heading} />
                        <div className="about-service-left">
                            <h2>We design digital solutions with unmatched user experience</h2>
                            <p>A best-in-class combination of advanced technology and design is what captivates today’s digital world. Crafting delightful user experiences over a decade, Successive Digital builds world-class applications paired with elegance, intuitive styles, visuals, sounds, and interactions to drive meaningful customer conversations.</p>
                            <p>We create a unique digital experience for everything connected across key industries and offer UI/ UX design services tailor-made for complex B2B solutions and consumer apps. Our experienced design team follows modern market trends, including most of human-like interfaces, and brings peace to products with desired outcomes.</p>
                            <p>As an award-winning design studio with global capabilities, Successive Digital expertise helps in rendering a completely satisfying user experience. We offer out-of-the-box responsive web design, brand identity, product design, creative consultancy, prototypes, and professional UI/ UX services that fuel digital transformation at scale.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="service-card-section space-one">
                <div className="container">
                    <p className='title center'>About Us</p>
                    <h2 className="center">We enjoy transforming your idea to life with our ambitious creative design team. Experience quality-rich designs built on robust interface architectures and visually appealing graphics from our experts that bring solid results to your products.</h2>
                    <div className="row comman-card">
                    {
                        cardcontent.map((content) => {
                            return (
                                <ServiceCard {...content} />
                            )
                        })
                    }
                    </div>
                </div>
            </section>
            <CustomerStory />
            <RelatedInsights />
            <Cta />
        </>
    );
}
export default ServicePage