import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Footer.css';


function Footer() {
    const address = [
        { id: 3, contries: 'India - Noida', street: 'Windsor Grand 4th floor, Plot number, 1- C, Sector 126, Noida, Uttar Pradesh , 201313', phoneNumber: '+91-120-425-9482' },
        { id: 4, contries: 'India - Pune', street: 'Sky Loft, Creaticity Mall, Shastrinagar, Yerawada, Pune, Maharashtra , 411006', phoneNumber: '+91-986-065-0546' },
        { id: 1, contries: 'United States - Texas (HQ)', street: '606 Lake Park Drive Coppell, Texas, 75019', phoneNumber: '+1-888-603-7086' },
        { id: 2, contries: 'United States - Raleigh', street: 'Centennial Campus,1017 Main Campus, Dr Raleigh NC, 27606', phoneNumber: '+1-888-603-7086' },
        { id: 8, contries: 'United States', street: '325 N Saint Paul St‍Suite 3100 Dallas, TX 75201 United States', phoneNumber: '+1-888-603-7086' },
        { id: 5, contries: 'Dubai', street: 'Parkside Retail Level - Cluster R - Jumeirah Lake Towers - Dubai, 500001', phoneNumber: '+971-56-800-8025' },
        { id: 6, contries: 'South Africa - Johannesburg', street: 'Parktown North, Johannesburg, 2193', phoneNumber: '+27-79-699-1180' },
        { id: 7, contries: 'United Kingdom - London', street: '7 Hills solutions limited 35 Gatcombe, Great holm, Milton Keynes, MK8 9EA', phoneNumber: '+44-776-091-7756' }

    ]
    const settings = {
        dots: false,
        arrow: true,
        infinite: true,
        autoplay: true,
        speed: 1000,
        slidesToShow: 4,
        slidesToScroll: 1,
    };
    return (
        < footer className="space footer">
            <div className="container">
                <div className="footer-contact-label-wrapper">
                    <div className="footer-contact-label">Contact Us</div>
                    <div className="footer-contact-label-shape"></div>
                </div>
                <Slider {...settings}>
                    {
                        address.map((add, id) => {
                            return (
                                <div className="address" key={id}>
                                    <p className="country">{add.contries}</p>
                                    <p>{add.street}</p>
                                    <p className="country">Contact No.- <a href={'tel:' + add.phoneNumber} className="phone-number">{add.phoneNumber}</a></p>
                                </div>
                            )
                        })
                    }
                </Slider>

            </div>
            <div className="footer-link">
                <div className="container">
                    <ul className="row">
                        <li><a href="/">Capbilities</a></li>
                        <li><a href="/">Success stories</a></li>
                        <li><a href="/">Insites</a></li>
                        <li><a href="/">Parterner</a></li>
                    </ul>
                </div>
            </div>
            <div className="container social-link">
                <ul className="row">
                    <li>
                        <a href="https://www.facebook.com/SuccessiveDigital/" target="_blank" rel="noreferrer">
                            <img src="https://assets-global.website-files.com/641da72be74599f82c494cd0/6564411da1c6e64258f7e389_twitter.png" alt="icon" />
                        </a>
                    </li>
                    <li>
                        <a href="https://www.facebook.com/SuccessiveDigital/" target="_blank" rel="noreferrer"><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 24 24">
                            <path d="M17.525,9H14V7c0-1.032,0.084-1.682,1.563-1.682h1.868v-3.18C16.522,2.044,15.608,1.998,14.693,2 C11.98,2,10,3.657,10,6.699V9H7v4l3-0.001V22h4v-9.003l3.066-0.001L17.525,9z">

                            </path>
                        </svg>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.linkedin.com/company/successivedigital" target="_blank" rel="noreferrer">
                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 30 30">
                                <path d="M9,25H4V10h5V25z M6.501,8C5.118,8,4,6.879,4,5.499S5.12,3,6.501,3C7.879,3,9,4.121,9,5.499C9,6.879,7.879,8,6.501,8z M27,25h-4.807v-7.3c0-1.741-0.033-3.98-2.499-3.98c-2.503,0-2.888,1.896-2.888,3.854V25H12V9.989h4.614v2.051h0.065 c0.642-1.18,2.211-2.424,4.551-2.424c4.87,0,5.77,3.109,5.77,7.151C27,16.767,27,25,27,25z">

                                </path>
                            </svg>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.instagram.com/successivedigital/" target="_blank" rel="noreferrer">
                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 50 50">
                                <path d="M 16 3 C 8.8324839 3 3 8.8324839 3 16 L 3 34 C 3 41.167516 8.8324839 47 16 47 L 34 47 C 41.167516 47 47 41.167516 47 34 L 47 16 C 47 8.8324839 41.167516 3 34 3 L 16 3 z M 16 5 L 34 5 C 40.086484 5 45 9.9135161 45 16 L 45 34 C 45 40.086484 40.086484 45 34 45 L 16 45 C 9.9135161 45 5 40.086484 5 34 L 5 16 C 5 9.9135161 9.9135161 5 16 5 z M 37 11 A 2 2 0 0 0 35 13 A 2 2 0 0 0 37 15 A 2 2 0 0 0 39 13 A 2 2 0 0 0 37 11 z M 25 14 C 18.936712 14 14 18.936712 14 25 C 14 31.063288 18.936712 36 25 36 C 31.063288 36 36 31.063288 36 25 C 36 18.936712 31.063288 14 25 14 z M 25 16 C 29.982407 16 34 20.017593 34 25 C 34 29.982407 29.982407 34 25 34 C 20.017593 34 16 29.982407 16 25 C 16 20.017593 20.017593 16 25 16 z">

                                </path>
                            </svg>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.youtube.com/@SuccessiveDigital" target="_blank" rel="noreferrer">
                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 24 24">
                                <path d="M21.582,6.186c-0.23-0.86-0.908-1.538-1.768-1.768C18.254,4,12,4,12,4S5.746,4,4.186,4.418 c-0.86,0.23-1.538,0.908-1.768,1.768C2,7.746,2,12,2,12s0,4.254,0.418,5.814c0.23,0.86,0.908,1.538,1.768,1.768 C5.746,20,12,20,12,20s6.254,0,7.814-0.418c0.861-0.23,1.538-0.908,1.768-1.768C22,16.254,22,12,22,12S22,7.746,21.582,6.186z M10,15.464V8.536L16,12L10,15.464z">
                                </path>
                            </svg>
                        </a>
                    </li>
                </ul>
            </div>
        </footer>
    );
}
export default Footer