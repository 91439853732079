import './CommanComponent.css';

const ServiceCard = ({ image, heading, text, id, colors }) => {
    return (
        <div className="card" style={{ backgroundColor: colors }} key={id}>
            <img src={image} alt={heading} />
            <h3>{heading}</h3>
            <p>{text}</p>
        </div>
    );
}
export default ServiceCard;