import './App.css';
import Home from './Pages/Home/Home'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from './Nav/Header';
import Footer from './Component/Footer/Footer';
import ServicePage from './Pages/Service/Servicepage';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect, useState } from 'react';
import CaseStudy from './Pages/CaseStudy/CaseStudy';



function App() {
  const [lineHight, setLineHight] = useState(0);
  const stoppingPoint = 500;

  const handleScroll = () => {
    const scrolled = window.scrollY;
    setLineHight(Math.min(scrolled, stoppingPoint));

  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    AOS.init({ once: true });
  }, []);
  useEffect(() => {
    document.title = window.location.href; 
    return () => {
      document.title = 'Default Page Title';
    };
  }, []);
 
  return (
    <div className="App">
      <div className='container'>
        <div className="verticalline" style={{ height: `${lineHight}px` }}></div>
      </div>
      <div className="main" >
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Header />}>
              <Route index element={<Home />} />
              <Route path="service" element={<ServicePage />} />
              <Route path="case-studies" element={<CaseStudy />} />

            </Route>
          </Routes>
        </BrowserRouter>
      </div>
      <Footer />
    </div>
  );
}

export default App;
