import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Home.css'

const Capbilities = () => {

    let capsettings = {
        dots: false,
        infinite: true,
        speed: 500,
        arrows: true,
        slidesToShow: 3,
        slidesToScroll: 3
    };

    return (
        <>
            <Slider {...capsettings} className="cap">
                <div className='card'>
                    <h3>
                        <a href="/" className="cap-link"><span>Consulting</span> <span className="arrow">↗</span></a>

                    </h3>
                    <p>
                        Instill continued innovation to stay ahead of the competition. Successive offers proven framework and enterprise-grade capabilities to enable businesses to de-risk and accelerate long-term transformation across product development, modernization, installation, integration, upgrade, operations management, and others.                    </p>
                    <ul>

                        <li><a href="/">Product Engineering</a></li>
                        <li><a href="/">Digital Strategy And Transformation </a></li>
                        <li><a href="/">Technology Advisory </a></li>
                    </ul>
                </div>
                <div className='card'>
                    <h3>
                        <a href="/" className="cap-link"><span>Digital Experiences</span> <span className="arrow">↗</span></a>

                    </h3>
                    <p>
                        Evolving technologies hold the power to unlock new business value, and that’s exactly what Successive helps organizations achieve with proven digital experience capabilities. We help companies remodel their business by incorporating digital technology across appropriate facets to bring greater efficiencies, collaboration, delivery speed, and customer satisfaction.                     </p>
                    <ul>

                        <li><a href="/">UX Design</a></li>
                        <li><a href="/">Digital Commerce</a></li>
                        <li><a href="/">Customer Experience</a></li>
                    </ul>

                </div>
                <div className='card'>
                    <h3>
                        <a href="/" className="cap-link"><span>Cyber Security</span> <span className="arrow">↗</span></a>

                    </h3>
                    <p>
                        Strengthen your company's defenses by utilizing cyber security's power. Successive safeguards your business with a resilient cybersecurity strategy and proactive security measures. Our all-inclusive cybersecurity solutions empower organizations to fortify their digital assets, defend against threats, and ensure data protection.                    </p>
                    <ul>

                        <li><a href="/">Application VAPT</a></li>
                        <li><a href="/">Cloud Security Assessment</a></li>
                        <li><a href="/">Cloud Threat Modeling</a></li>
                    </ul>
                </div>
                <div className='card'>
                    <h3>
                        <a href="/" className="cap-link"><span>Application</span> <span className="arrow">↗</span></a>

                    </h3>
                    <p>
                        Rethink and reinvent your business models with trusted and modern foundations supported by cloud, distributed applications, data, and automation. Successive leverages its decade of experience and expertise to enable clients to adopt modern processes, technologies, and tools in enterprise application development, go-to-market strategies, M&A, and more.                    </p>
                    <ul>

                        <li><a href="/">Enterprise Application Development</a></li>
                        <li><a href="/">Quality Engineering And Assurance</a></li>
                        <li><a href="/">Legacy Application Modernization</a></li>
                    </ul>
                </div>
                <div className='card'>
                    <h3>
                        <a href="/" className="cap-link"><span>Commerce</span> <span className="arrow">↗</span></a>

                    </h3>
                    <p>
                    We enable personalized and tailored customer experiences across all touchpoints of a business-customer engagement cycle. Putting customers at the core, our Digital Centre of Excellence creates end-to-end digital solutions, allowing businesses to provide exceptional user experiences and enhanced customer acquisition.                    </p>
                    <ul>

                        <li><a href="/">Instill</a></li>
                        <li><a href="/">Instill</a></li>
                        <li><a href="/">Instill</a></li>
                    </ul>
                </div>
                <div className='card'>
                    <h3>
                        <a href="/" className="cap-link"><span>Application</span> <span className="arrow">↗</span></a>

                    </h3>
                    <p>
                        Instill continued innovation to stay ahead of the competition. Successive offers proven framework and enterprise-grade capabilities to enable businesses to de-risk and accelerate long-term transformation across product development, modernization, installation, integration, upgrade, operations management, and others.
                    </p>
                    <ul>

                        <li><a href="/">Instill</a></li>
                        <li><a href="/">Instill</a></li>
                        <li><a href="/">Instill</a></li>
                    </ul>
                </div>
            </Slider>
        </>
    );
};

export default Capbilities;
