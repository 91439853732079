import CaseStudyAboutSection from '../../Component/CaseStudyAboutSection/CaseStudyAboutSection';
import Banner from '../../Component/CaseStudyBanner/CaseStudyBanner';
import CaseStudyOurOffering from '../../Component/CaseStudyOurOfferingSection/CaseStudyOurOffering';
import MoreMCaseStudies from '../../Component/More-Case-Studies/MoreMCaseStudies';

import './CaseStudy.css'

const CaseStudy = () => {
    return (
        <>
            <Banner />
            <section className='case-main'>
                <CaseStudyAboutSection />
                <CaseStudyOurOffering />
                <MoreMCaseStudies />
            </section>
        </>
    );
}
export default CaseStudy;