import './Home.css';
import Service from './Service';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import aboutus from './Image/about us.png';
import Capbilities from './Capbilities';
import { useEffect, useState } from 'react';
import Clients from './Clients';
import Solution from './Solution';
import { solution } from './Api'
import { Link } from 'react-router-dom';
import Aws from './Image/Client-Image/ws-logo.svg'
import GoogleCloud from './Image/Client-Image/google.svg'
import Microsoft from './Image/Client-Image/microsoft-logo.svg'
import Strapi from './Image/Client-Image/strap.svg'
import Adobe from './Image/Client-Image/Adobe-logo.svg'
import Contentful from './Image/Client-Image/Contentful.svg'
import StoryBook from './Image/Client-Image/Storyblok.png'
import Acquia from './Image/Client-Image/Acquia.svg'
import BigCommerce from './Image/Client-Image/Bigcommerce.png'
import Shopify from './Image/Client-Image/Shopify.png'
import Microfocus from './Image/Client-Image/ws-logo.svg'
import Databrics from './Image/Client-Image/Databricks.png'


function Home() {
  const [counter, setCounter] = useState(0);
  const length = 20;

  let settings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: false,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const count = () => {
    setTimeout(() => {
      setCounter((prevCounter) => prevCounter + 1);
    }, 100);
  };

  useEffect(() => {
    if (counter < length) {
      count();
    }
  }, [counter]); // Run the effect whenever the counter changes
  return (
    <>
      <Slider {...settings} className='banner-slider'>
        <div className='banner-frontpage'>
          <div className='videoTag'>
            <video autoPlay loop muted>
              <source src="https://8904598.fs1.hubspotusercontent-na1.net/hubfs/8904598/Successive%20Digital%20Video%20file/bannerVideo1.mp4" type='video/mp4' />
            </video>
          </div>
          <div className='container '>
            <div className='inner-banner'>
              <h1 data-aos="fade-up"
                data-aos-duration="500">Redefining Digital Experiences </h1>
              <p data-aos="fade-up"
                data-aos-duration="500">Empowering business transformation and digital experience to achieve sustainable success through data, technology, and people. A full spectrum digital transformation and product engineering company to fuel your business growth.</p>
            </div>
          </div>
        </div>
        <div>
          <div className='videoTag'>
            <video autoPlay loop muted>
              <source src="https://8904598.fs1.hubspotusercontent-na1.net/hubfs/8904598/Successive%20Digital%20Video%20file/bannerVideo2.mp4" type='video/mp4' />
            </video>
          </div>
          <div className='container '>
            <div className='inner-banner'>
              <h1 className='nokia-heading' data-aos="fade-up"
                data-aos-duration="500">Custom Dashboard Solution Designed with Data Architecture for the Technology Giant </h1>
              <p className='nokia' data-aos="fade-up"
                data-aos-duration="500">Nokia</p>
            </div>
          </div>

        </div>
        <div>
          <div className='videoTag'>
            <video autoPlay loop muted>
              <source src="https://8904598.fs1.hubspotusercontent-na1.net/hubfs/8904598/Successive%20Digital%20Video%20file/bannerVideo3.mp4" type='video/mp4' />
            </video>
          </div>
          <div className='container '>
            <div className='inner-banner'>
              <h1 className='nokia-heading'data-aos="fade-up"
                data-aos-duration="500">Custom Dashboard Solution Designed with Data Architecture for the Technology Giant </h1>
              <p className='nokia' data-aos="fade-up"
                data-aos-duration="500">Densu</p>
            </div>
          </div>

        </div>
      </Slider>
      <section className='about-section space'>
        <div className='container-type-two'>
          <p className='left-text'>About Us</p>
        </div>
        <div className='container'>
          <div className='row'>
            <img src={aboutus} alt='abut'data-aos="fade-up"
                data-aos-duration="500" />
            <div className='left-about'>
              <p className='title' data-aos="fade-up"
                data-aos-duration="500">About Us</p>
              <h2 data-aos="fade-up" data-aos-easing="linear"
                data-aos-duration="500">Empowering Growth Through Digital Solutions</h2>
              <p className='paragraph' data-aos="fade-up">As a trusted industry leader in digital solutions, we drive innovation and foster growth for our valued customers and communities. Our strong partnerships with world-leading companies and partners enable us to shape the future of the digital frontier through pioneering sustainable growth and best-in-class solutions. We are committed to building lasting relationships with industry leaders to co-create the digital landscape of tomorrow, driven by growth, development, and intelligent solutions.</p>
              <Link to="/service" className='connect-now' data-aos="fade-up" data-aos-easing="linear"
                data-aos-duration="500"> <span className='circle'></span> Connect Now ➔</Link>
            </div>
          </div>
        </div>
      </section>
      <section className='solution space-one'>
        <div className='container-type-two'>
          <p className='left-text'>Our <br /> Solutions</p>
        </div>
        <div className='container'>
          <p className='title center' data-aos="fade-up"
                data-aos-duration="500">Our Solutions</p>
          <h2 className='center' data-aos="fade-up"
                data-aos-duration="500">Creating Digital Solutions Driven by Resiliency and Transformation</h2>
          <div className='row'>
            <Solution solution1={solution} />
          </div>
        </div>
      </section>
      <section className='video-section'>
        <video autoPlay loop muted >
          <source src="https://8904598.fs1.hubspotusercontent-na1.net/hubfs/8904598/Successive%20Digital%20Video%20file/Successive_Digital_AdobeExpress-transcode.mp4" type='video/mp4' />
        </video>
      </section>
      <section className='service space-one'>
        <div className='container-type-two'>
          <p className='left-text'>Our <br /> Service</p>
        </div>
        <div className='container'>
          <p className='title center'data-aos="fade-up"
                data-aos-duration="500">Our Services</p>
          <h2 className='center'data-aos="fade-up"
                data-aos-duration="500">The 5Cs of Digital Transformation</h2>
          <Service />
        </div>
      </section>
      <section className='capbilities space-one'>
        <div className='container-type-two'>
          <p className='left-text'>Our <br /> Capbilities</p>
        </div>
        <div className='container'>
          <p className='title center'>Our Capbilities</p>
          <h2 className='center'>From Digital Innovation to Enterprise Excellence -We've Got You Covered</h2>
          <Capbilities />
          <a href='/' className='read-more'>Tap or press the arrow to redirect for more information.</a>
        </div>
      </section>
      <section className='partner space-one'>
        <div className='container-type-two'>
          <p className='left-text'>Partners and Alliances</p>
        </div>
        <div className='container'>
          <p className='title center'data-aos="fade-up"
                data-aos-duration="500">Partners and Alliances</p>
          <h2 className='center'data-aos="fade-up"
                data-aos-duration="500">Creating Digital Solutions Driven by Resiliency and Transformation</h2>

          <div className='row'>
            <div className='client-icon'>
              <img src={Aws} alt='Aws' />
            </div>
            <div className='client-icon'>
              <img src={GoogleCloud} alt='GoogleCloud' />
            </div>
            <div className='client-icon'>
              <img src={Microsoft} alt='Microsoft' />
            </div>
            <div className='client-icon'>
              <img src={Strapi} alt='Strapi' />
            </div>
            <div className='client-icon'>
              <img src={Adobe} alt='Adobe' />
            </div>
            <div className='client-icon'>
              <img src={Contentful} alt='Contentful' />
            </div>
            <div className='client-icon'>
              <img src={StoryBook} alt='StoryBook' />
            </div>
            <div className='client-icon'>
              <img src={Acquia} alt='Acquia' />
            </div>
            <div className='client-icon'>
              <img src={BigCommerce} alt='BigCommerce ' />
            </div>
            <div className='client-icon'>
              <img src={Shopify} alt='Shopify' />
            </div>
            <div className='client-icon'>
              <img src={Microfocus} alt='Microfocus' />
            </div>
            <div className='client-icon'>
              <img src={Databrics} alt='Databrics' />
            </div>
          </div>
        </div>
      </section>
      <section className='case-studies-home space-one'>
        <div className='container-type-two'>
          <p className='left-text'>Case Studies</p>
        </div>
        <div className='container'>
          <p className='title center'data-aos="fade-up"
                data-aos-duration="500">Case Studies</p>
          <h2 className='center'data-aos="fade-up"
                data-aos-duration="500">Success Stories in Action</h2>
          <div className='row'>
            <div className='left-case-studies'>
              <div className='cards'>
                <Link to='/' >
                  <h3 className='case-studies-heading'data-aos="fade-up"
                data-aos-duration="500">
                    Digital Healthcare
                  </h3>
                  <p data-aos="fade-up"
                data-aos-duration="500">Successive helped a digital healthcare provider modernize its legacy application code for web development. Now they connect patients and providers through a single platform and launched a 3-sided delivery model that allows real-time access, capacity, and compliance management.</p>
                </Link >
              </div>
            </div>
            <div className='right-case-studies'>
              <div className='right-case-studies-card one'>
                <div className='right-case-studies-card-inner'>
                  <Link to='/' >
                    <h3 className='case-studies-heading'>
                      Digital Healthcare
                    </h3>
                    <p>Successive helped a digital healthcare provider modernize its legacy application code for web development. Now they connect patients and providers through a single platform and launched a 3-sided delivery model that allows real-time access, capacity, and compliance management.</p>
                  </Link >
                </div>
              </div>
              <div className='right-case-studies-card two'>
                <div className='right-case-studies-card-inner'>
                  <Link to='/' >
                    <h3 className='case-studies-heading'>
                      Digital Healthcare
                    </h3>
                    <p>Successive helped a digital healthcare provider modernize its legacy application code for web development. Now they connect patients and providers through a single platform and launched a 3-sided delivery model that allows real-time access, capacity, and compliance management.</p>
                  </Link >
                </div>
              </div>
              <div className='right-case-studies-card three'>
                <div className='right-case-studies-card-inner'>
                  <Link to='/' >
                    <h3 className='case-studies-heading'>
                      Digital Healthcare
                    </h3>
                    <p>Successive helped a digital healthcare provider modernize its legacy application code for web development. Now they connect patients and providers through a single platform and launched a 3-sided delivery model that allows real-time access, capacity, and compliance management.</p>
                  </Link >
                </div>
              </div>
              <div className='right-case-studies-card four'>
                <div className='right-case-studies-card-inner'>
                  <Link to='/' >
                    <h3 className='case-studies-heading'>
                      Digital Healthcare
                    </h3>
                    <p>Successive helped a digital healthcare provider modernize its legacy application code for web development. Now they connect patients and providers through a single platform and launched a 3-sided delivery model that allows real-time access, capacity, and compliance management.</p>
                  </Link >
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='clients space-one'>
        <div className='container-type-two'>
          <p className='left-text'>Our <br /> Clients</p>
        </div>
        <div className='container'>
          <p className='title center'>Our Clients</p>
          <h2 className='center'>Global Data Insights</h2>
          <div className='row'>
            <Clients initialCount={0} length={10} text={"Years in Business"} plus={"+"} />
            <Clients initialCount={40} length={50} text={"Capabilities"} plus={"+"} />
            <Clients initialCount={290} length={300} text={"Clients on Board"} plus={"+"} />
            <Clients initialCount={0} length={7} text={"Offices Worldwide"} />
            <Clients initialCount={0} length={8} text={"People on Board"} plus={"+"} />
          </div>
        </div>
      </section>
      <section className='events space-one'>
        <div className='container-type-two'>
          <p className='left-text'>Featured<br />Events</p>
        </div>
        <div className='container'>
          <p className='title center'>Featured Events</p>
          <h2 className='center'>Discover the Highlights</h2>
          <div className='row'>
            <div className='card'>
              <img src='https://assets-global.website-files.com/641da72be74599f82c494cd0/646db4efcc9b83dfe40bad88_featured-events-3.webp' alt='image' />
              <h3>ITS Business Summit 2023</h3>
              <p>Guest of honour address at the CII Digital Transformation Summit 2023 by Successive Digital – Chief Strategy Officer, Mr. Gaurav Malik and speaking at an exclusive panel on “Emerging Technology Roadmap” along with global technology leaders, in New Delhi.</p>
            </div>
            <div className='card'>
              <img src='https://assets-global.website-files.com/641da72be74599f82c494cd0/6488008b2569738adc46b0cd_event3.webp' alt='imge' />
              <h3>CII DX Summit 2023</h3>
              <p>Gaurav Malik ( Gary ) – Chief Strategy Officer, Successive Digital - participated as a distinguished speaker to address the keynote session on “Rethinking and Reimagining Business in Circular Economy” through digital transformation. The event was hosted by the ITS Education Group, India.</p>
            </div>
            <div className='card'>
              <img src='https://assets-global.website-files.com/641da72be74599f82c494cd0/646db4f0d6e89f748a170352_featured-events-1.webp' alt='image' />
              <h3>Agile Network India 2023</h3>
              <p>Successive Digital hosted the Noida, India chapter of Agile Network at their premises. The event witnessed participation of many #technology enthusiast and industry leaders from digital transformation space. Discussions revolved around running fixed cost projects using agile approach.</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Home;
