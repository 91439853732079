import Enterprise from './images/RelatedInsights/Artboard 1.png'
import Advanced from './images/RelatedInsights/Artboard 2.png'
import Personalization from './images/RelatedInsights/Generative AI.png'

const RelatedInsights = () => {

    return (
        <section className="insights space-one">
            <div className='container-type-two'>
                <p className='left-text'>Customer <br />Story</p>
            </div>
            <div className="container">
                <p className='title center'>Customer Story</p>
                <h2 className="center">Moving the Digital Fleet Forward</h2>
                <div className="row">
                    <div className="insights-card">
                        <img src={Enterprise} alt="Enterprise" />
                        <p>Enterprise applications' unmatched capacity to orchestrate synergy across disparate departments enables...</p>
                        <a href='/' className='connect-now'> <span className='circle'></span> Connect Now ➔</a>
                    </div>
                    <div className="insights-card">
                        <img src={Advanced} alt="Advanced" />
                        <p>Advanced information analytics is at the frontline of a healthcare revolution. It can transform patient care, streamline...</p>
                        <a href='/' className='connect-now'> <span className='circle'></span> Connect Now ➔</a>
                    </div>
                    <div className="insights-card">
                        <img src={Personalization} alt="Personalization" />
                        <p>In the age of personalization, generative AI empowers businesses to create tailored customer experiences...</p>
                        <a href='/' className='connect-now'> <span className='circle'></span> Connect Now ➔</a>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default RelatedInsights;
