const Solution = ({ solution1 }) => {
    
    return (
        <>
            {solution1.map((solution1) => {
                return (
                    <div className='solution-box'  key={solution1.number}>
                        <p className='number center'>{solution1.number}</p>
                        <div className='solution-inner-box' style={{backgroundImage:`url("${solution1.image}")`}}>
                            <div className='solution-content-box'>
                                <h3>{solution1.heading}</h3>
                                <p className='solution-content'>{solution1.text}</p>
                            </div>
                        </div>
                    </div>
                )
            })}

        </>

    );
}
export default Solution;