import React, { useState, useEffect } from "react";


const Clients = ({ initialCount, length, text, plus }) => {
    const [counter, setCounter] = useState(initialCount);

    useEffect(() => {
      if (counter < length) {
        const timeoutId = setTimeout(() => {
          setCounter((prevCounter) => prevCounter + 1);
        }, 100);
  
        return () => clearTimeout(timeoutId);
      }
    }, [counter, length]);
    return (
        <div className='clients-number'>
            <p>{text}</p>
            <span>{counter}{plus}</span>
        </div>
    );
}
export default Clients;