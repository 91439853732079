import { Outlet, Link } from "react-router-dom";
import './Header.css';

function Header() {
    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
        });
      };
    return (
        <>
            <header>
                <div className='container'>
                    <div className='row'>
                        <div className='logo'>
                        <Link to="/" ><img src='https://assets-global.website-files.com/641da72be74599f82c494cd0/64ae83b6afe5f535912c1ce3_SuccessiveDigitalLogo-LightBG-p-500.png' alt='Logo' /></Link>
                        </div>
                        <nav>
                            <ul className='row'>
                                <li><Link to="/" onClick={scrollToTop}>Home</Link></li>
                                <li><Link to="/service" onClick={scrollToTop}>Service</Link></li>
                                <li><Link to="/case-studies" onClick={scrollToTop}>Case Study</Link></li>
                                <li><Link to="/service" onClick={scrollToTop}>Resourse</Link></li>
                                <li><Link to="/service" className='btn-contact'>Contact Us</Link></li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </header>
            <Outlet />

        </>
    );
}

export default Header;
