import './CaseStudyBanner.css'
import Facebook from './image/facebook.png';
import Tweeter from './image/twitter.png';
import Linkdin from './image/linkedin.png'

const CaseStudyBanner = () => {
    return (
        <>
            <section className="casestudies-banner">
                <div className='container'>
                    <div className='casestudy-banner-inner'>
                        <h1 className='center' data-aos="fade-up" data-aos-easing="linear"
                        data-aos-duration="500">Moving the Digital Fleet Forward</h1>
                        <p className='center' data-aos="fade-up" data-aos-easing="linear"
                        data-aos-duration="500">Driving safer, smarter, and greener maritime with custom-build digital solutions for the global shipping and logistics industry. Featuring real-time performance monitoring, weather routing and forecasting, sensor integration, vessel tracking, monitoring, and reporting</p>
                        <div className='row'>
                            <a href='https://www.facebook.com/sharer/sharer.php?u=https://successive-digital.webflow.io/case-studies/moving-the-digital-fleet-forward%2F&title=Moving the Digital Fleet Forward%3F' data-aos="fade-up" data-aos-easing="linear"
                        data-aos-duration="500"><img src={Facebook} alt='facebook' /></a>
                            <a href='https://twitter.com/share?url=https://successive-digital.webflow.io/case-studies/moving-the-digital-fleet-forward%2F&title=Moving the Digital Fleet Forward&summary=' data-aos="fade-up" data-aos-easing="linear"
                        data-aos-duration="500"><img src={Tweeter} alt='Tweeter' /></a>
                            <a href='https://www.linkedin.com/shareArticle?mini=true&url=https://successive-digital.webflow.io/case-studies/moving-the-digital-fleet-forward%2F&title=Moving the Digital Fleet Forward&summary=' data-aos="fade-up" data-aos-easing="linear"
                        data-aos-duration="500"><img src={Linkdin} alt='Linkdin' /></a>
                        </div>
                    </div>
                    <video autoPlay loop muted className='casestudy-video' data-aos="fade-up" data-aos-easing="linear"
                        data-aos-duration="500">
                        <source src="https://8904598.fs1.hubspotusercontent-na1.net/hubfs/8904598/Successive%20Digital%20Video/startumfive.mp4" type="video/mp4" />
                    </video>
                </div>
            </section>
           
        </>
    );
}
export default CaseStudyBanner;