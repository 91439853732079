import Pattern from './images/right-design-pettern.svg';

const Cta = () => {
    return (
        <section className="cta space-one">
            <div className='container-type-two'>
                <p className='left-text'>Customer <br />Story</p>
            </div>
            <div className="container">
                <div className="inner-cta">
                    <div className="row">
                        <div className="left">
                            <p className="advantage">Successive Advantage</p>
                            <p className="design">We design solutions that bring unmatchable customer experience to life and help companies accelerate their growth agendas with breakthrough innovation.</p>
                            <a href='/' className='connect-now'> <span className='circle'></span> Connect Now ➔</a>
                            <img src={Pattern} alt="pattern" className='pattern-top' />
                        </div>
                        <div className="right">
                            <img src={Pattern} alt="pattern" className='pattern' />
                            <svg width="250" height="181" viewBox="0 0 250 181" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M120.451 98.6321L120.518 98.6993L206.747 176.489C206.747 176.489 212.937 183.574 219.126 176.489L237.483 157.215C237.483 157.215 248.675 143.896 249.839 127.744C251.003 111.593 245.877 96.5727 234.214 83.8241C227.017 75.9556 200.02 51.9359 180.377 34.6655C180.377 34.6767 151.466 65.524 120.451 98.6321Z" fill="url(#paint0_linear_214_24)" />
                                <path d="M95.5792 75.967L120.449 98.6323C151.453 65.5242 180.375 34.6657 180.375 34.6657C168.164 23.9319 158.796 15.8172 158.796 15.8172C158.796 15.8172 144.122 1.96052 125.285 0.136098C125.285 0.136098 115.536 -0.55785 107.589 1.18822C97.8737 3.31484 86.9831 8.19487 74.7047 22.6223L24.0352 76.6609C24.0352 76.6609 58.0387 40.3964 95.5792 75.967Z" fill="url(#paint1_linear_214_24)" />
                                <path d="M46.109 178.19C50.519 173.232 120.53 98.7105 120.53 98.7105L120.462 98.6433L95.5922 75.978C69.916 51.6449 45.8963 60.9238 33.2821 69.2176C29.1968 72.0494 25.0219 75.6422 20.791 80.1529C20.791 80.1529 -17.6225 112.735 9.9788 150.857L37.4459 177.922C37.4235 177.899 41.6991 183.149 46.109 178.19Z" fill="url(#paint2_linear_214_24)" />
                                <defs>
                                    <linearGradient id="paint0_linear_214_24" x1="249.645" y1="136.969" x2="134.566" y2="73.1791" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#03A8F4" />
                                        <stop offset="1" stop-color="#00CDF9" />
                                    </linearGradient>
                                    <linearGradient id="paint1_linear_214_24" x1="102.21" y1="98.6361" x2="102.21" y2="-0.00291615" gradientUnits="userSpaceOnUse">
                                        <stop offset="0.1403" stop-color="#03ABF4" />
                                        <stop offset="1" stop-color="#00CDF9" />
                                    </linearGradient>
                                    <linearGradient id="paint2_linear_214_24" x1="60.2561" y1="180.319" x2="60.2561" y2="67.5852" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#006FD1" />
                                        <stop offset="1" stop-color="#3251A6" />
                                    </linearGradient>
                                </defs>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default Cta;