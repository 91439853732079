import './CaseStudyAboutSection.css'

const CaseStudyAboutSection = () => {
    return (
        <>
            <section className="casestudies-about space-one">
                <div className='container-type-two'>
                    <p className='left-text'>About this <br />Project</p>
                </div>
                <div className='container'>
                    <div className='casestudies-about-inner'>
                        <h2 data-aos="fade-up" data-aos-easing="linear"
                            data-aos-duration="500" className='center'>Empowering Growth Through Digital Solutions</h2>
                        <p data-aos="fade-up" data-aos-easing="linear"
                            data-aos-duration="500" className='center'>The client acquired multiple businesses to cater to a large section of its audience and extends its existing portfolio on a global scale. The company wanted to unify its multi-vendor system on a common platform, onboarding its new users to a modernized and centrally controlled platform while ensuring business continuity over traditional services. We provided the client with a robust cloud-based architecture to support the union of multiple businesses and serve its users with a single operating model with advanced digital features while enabling a seamless transition of the existing line of services over a more efficient custom-built application.</p>
                        <div className='row location'>
                            <div>
                                <p className='scop' data-aos="fade-up" data-aos-easing="linear"
                                    data-aos-duration="500">Scope</p>
                                <p className='region' data-aos="fade-up" data-aos-easing="linear"
                                    data-aos-duration="500">Digital Strategy, Operational Excellence, Customer Experience</p>
                            </div>
                            <div>
                                <p className='scop' data-aos="fade-up" data-aos-easing="linear"
                                    data-aos-duration="500">Region</p>
                                <p className='region' data-aos="fade-up" data-aos-easing="linear"
                                    data-aos-duration="500">USA</p>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <section className='about-the-client space-one'>
                <div className='container-type-two'>
                    <p className='left-text'>About this <br />Project</p>
                </div>
                <div className='container'>
                    <h2 data-aos="fade-up" data-aos-easing="linear"
                        data-aos-duration="500">About the client</h2>
                    <p data-aos="fade-up" data-aos-easing="linear"
                        data-aos-duration="500">Our client is one of the leading healthcare companies that provides intuitive care plans, patient engagement tools, and automated administration. They strategically use artificial intelligence tools to solve public health problems, such as addressing preventable diseases and death, reducing health disparities, and empowering the front-line public health workforce by helping them reduce burnout.</p>
                    <div className='row'>
                        <img src='https://assets-global.website-files.com/641da72be745998690494cd6/64c77c77032bc429d1a27685_Artboard%201%20copy.png' alt='bdf' data-aos="fade-up" data-aos-easing="linear"
                            data-aos-duration="500" />
                        <img src='https://assets-global.website-files.com/641da72be745998690494cd6/64c77c86a09dbdc13152a8a8_Artboard%201%20copy%202.png' alt='bdf' data-aos="fade-up" data-aos-easing="linear"
                            data-aos-duration="500" />
                    </div>
                </div>
            </section>
        </>
    );
}
export default CaseStudyAboutSection;