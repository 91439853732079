import './CommanComponent.css'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Moving from './images/CustomerStory/Moving-the-Digital-Fleet-Forward.png'

const CustomerStory = () => {
    let capsettings = {
        dots: false,
        infinite: true,
        speed: 500,
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    return (
        <section className="CustomerStory space-one">
            <div className='container-type-two'>
                <p className='left-text'>Customer <br />Story</p>
            </div>
            <div className='container'>
                <Slider {...capsettings}>
                    <div className='card-customerStory row'>
                        <div className='right'>
                        <img src={Moving} alt='Moving the Digital Fleet Forward' />

                        </div>
                        <div className='left'>
                            <p className='title'>Customer Story</p>
                            <h2>Moving the Digital Fleet Forward</h2>
                            <p>Driving safer, smarter, and greener maritime with custom-build digital solutions for the global shipping and logistics industry. Featuring real-time performance monitoring, weather routing and forecasting, sensor integration, vessel tracking, monitoring, and reporting</p>
                            <a href='/' className='connect-now'> <span className='circle'></span> Connect Now ➔</a>
                        </div>
                    </div>
                    <div className='card-customerStory row'>
                        <div className='right'>
                        <img src={Moving} alt='Moving the Digital Fleet Forward' />

                        </div>
                        <div className='left'>
                            <p className='title'>Customer Story</p>
                            <h2>Moving the Digital Fleet Forward</h2>
                            <p>Driving safer, smarter, and greener maritime with custom-build digital solutions for the global shipping and logistics industry. Featuring real-time performance monitoring, weather routing and forecasting, sensor integration, vessel tracking, monitoring, and reporting</p>
                            <a href='/' className='connect-now'> <span className='circle'></span> Connect Now ➔</a>
                        </div>
                    </div>
                    <div className='card-customerStory row'>
                        <div className='right'>
                        <img src={Moving} alt='Moving the Digital Fleet Forward' />

                        </div>
                        <div className='left'>
                            <p className='title'>Customer Story</p>
                            <h2>Moving the Digital Fleet Forward</h2>
                            <p>Driving safer, smarter, and greener maritime with custom-build digital solutions for the global shipping and logistics industry. Featuring real-time performance monitoring, weather routing and forecasting, sensor integration, vessel tracking, monitoring, and reporting</p>
                            <a href='/' className='connect-now'> <span className='circle'></span> Connect Now ➔</a>
                        </div>
                    </div>
                    <div className='card-customerStory row'>
                        <div className='right'>
                        <img src={Moving} alt='Moving the Digital Fleet Forward' />

                        </div>
                        <div className='left'>
                            <p className='title'>Customer Story</p>
                            <h2>Moving the Digital Fleet Forward</h2>
                            <p>Driving safer, smarter, and greener maritime with custom-build digital solutions for the global shipping and logistics industry. Featuring real-time performance monitoring, weather routing and forecasting, sensor integration, vessel tracking, monitoring, and reporting</p>
                            <a href='/' className='connect-now'> <span className='circle'></span> Connect Now ➔</a>
                        </div>
                    </div>
                </Slider>
            </div>
        </section>
    );
}
export default CustomerStory;