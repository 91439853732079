import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import './MoreMCaseStudies.css'

const MoreMCaseStudies = () => {
    let settingMoreMCaseStudies = {
        dots: false,
        infinite: true,
        speed: 500,
        arrows: true,
        slidesToShow: 2,
        slidesToScroll: 1
    };
    return (
        <>
            <div className='container-type-two'>
                <p className='left-text'>Our<br />offering</p>
            </div>

            <section className="moreCaseStudy space-one">
                <div className="container">
                    <Slider {...settingMoreMCaseStudies}>
                        <div className="moreCaseStuiesCard">
                            <img src="https://assets-global.website-files.com/641da72be745998690494cd6/64ad3cc5ea05539a1fef8264_Artboard%201%20copy%208.png" alt="" />
                            <a href="/" className="casestudiesLink center">Enhanced Patients Collaboration and Experience</a>
                            <p className="casestudiesText center">Personalized digital health platform for connected patients and care delivery. An award-winning AI-powered healthcare solution for communities, partners, payers, and providers. Implementing smart reporting and analytics, to improve clinical processes by over 60%.</p>
                        </div>
                        <div className="moreCaseStuiesCard">
                            <img src="https://assets-global.website-files.com/641da72be745998690494cd6/64ad3cc5ea05539a1fef8264_Artboard%201%20copy%208.png" alt="" />
                            <a href="/" className="casestudiesLink center">Enhanced Patients Collaboration and Experience</a>
                            <p className="casestudiesText center">Personalized digital health platform for connected patients and care delivery. An award-winning AI-powered healthcare solution for communities, partners, payers, and providers. Implementing smart reporting and analytics, to improve clinical processes by over 60%.</p>
                        </div>
                        <div className="moreCaseStuiesCard">
                            <img src="https://assets-global.website-files.com/641da72be745998690494cd6/64ad3cc5ea05539a1fef8264_Artboard%201%20copy%208.png" alt="" />
                            <a href="/" className="casestudiesLink center">Enhanced Patients Collaboration and Experience</a>
                            <p className="casestudiesText center">Personalized digital health platform for connected patients and care delivery. An award-winning AI-powered healthcare solution for communities, partners, payers, and providers. Implementing smart reporting and analytics, to improve clinical processes by over 60%.</p>
                        </div>
                        <div className="moreCaseStuiesCard">
                            <img src="https://assets-global.website-files.com/641da72be745998690494cd6/64ad3cc5ea05539a1fef8264_Artboard%201%20copy%208.png" alt="" />
                            <a href="/" className="casestudiesLink center">Enhanced Patients Collaboration and Experience</a>
                            <p className="casestudiesText center">Personalized digital health platform for connected patients and care delivery. An award-winning AI-powered healthcare solution for communities, partners, payers, and providers. Implementing smart reporting and analytics, to improve clinical processes by over 60%.</p>
                        </div>
                    </Slider>
                </div>
            </section>
            <section className="ctaimage space-one">
                <img data-aos="fade-up" data-aos-easing="linear"
                        data-aos-duration="500"  src="https://assets-global.website-files.com/641da72be745998690494cd6/64c77bd6f069fd9c47c21236_cta-p-1600.png" alt="" />
            </section>
        </>

    );

}
export default MoreMCaseStudies;